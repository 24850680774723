import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<div className="App">
            <header className="App-header">
              Home
            </header>
          </div>}>
        </Route>
        <Route path="*" element={<div className="App">
            <header className="App-header">
              Not found 
            </header>
          </div>}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
